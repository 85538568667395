import NextLink from 'next/link';
import React, { FC, useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import Iconify from 'src/components/iconify';
import { ComponentTypeProps } from 'src/types/page-generator';
import { ButtonColor, ButtonField } from 'src/types/generator';
import { IVirtualCard } from 'src/types/club-cards';
import { getClientListByMerchantId, getHistory } from 'src/api/citypeople';
import { DocumentImage, SupplierHistoryTransaction } from 'src/types/history-city-people';
import { getContentValueFromProps } from '../../utils';
import { useResponsive } from '../../../../hooks/use-responsive';

const CLIENT_NAME_TITLE = 'שם לקוח: ';
const PROJECT_NAME_TITLE = 'שם הפרויקט: ';
const ADDRESS_TITLE = 'כתובת: ';
const DATE_TITLE = 'תאריך: ';
const PRICE_TITLE = 'סכום: ';
const APPROVED_ORDER_BTN_TEXT = 'אישור הזמנה';

const SupplierHistoryCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const actionButtons = useMemo(() => getFieldValue('actionButtons'), [block]);
  const { state, dispatch } = useAppContext();
  const { customer } = state;
  const isClient = !state?.customer?.merchants?.length;
  const modalId = useMemo(() => getFieldValue('openDocumentModalId'), [block?.openDocumentModalId]);
  const isMobile = useResponsive('down', 'sm');

  const supplierHistory = state.orderHistory.supplierHistory || [];
  const filteredSupplierHistory = state.filterQuery
    ? state.filteredOrderHistory.supplierHistory || []
    : supplierHistory;

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  useEffect(() => {
    const merchantId = customer?.merchants?.[0]?.id ? +customer.merchants[0].id : null;
    getHistory(isClient, [], merchantId, dispatch)
      .catch(console.error)
      .finally(() => {
        dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
      });
  }, [customer, state.newOrder]);

  useEffect(() => {
    const merchantId = customer?.merchants?.[0]?.id ? +customer.merchants[0].id : null;
    getClientListByMerchantId(merchantId, dispatch);
  }, [customer]);

  const getButtonColor = (
    smbColor: ButtonColor | null | undefined,
    orderStatus?: string
  ): ButtonColor => {
    if (!smbColor) return 'primary';
    if (smbColor !== 'success') return smbColor;

    switch (orderStatus) {
      case 'PAID':
        return 'success';
      case 'CANCELLED':
        return 'primary';
      case 'PENDING':
        return 'warning';
      default:
        return 'error';
    }
  };

  return (
    <>
      {block?.settings?.variant === 'clientList' ? (
        <Container maxWidth={isMobile ? 'sm' : 'xl'}>
          <Stack width={1} margin="auto">
            <Stack direction="column" alignItems="center">
              {state.clientsList.map((client: any, id: number) => (
                <Box mt={1} width="100%" key={id}>
                  <Stack width={1} direction="row" justifyContent="space-between">
                    <Typography>
                      {CLIENT_NAME_TITLE}
                      <Typography component="span" fontWeight={600} mx={1}>
                        {client.firstName}
                      </Typography>
                    </Typography>
                  </Stack>
                  <Typography>
                    {ADDRESS_TITLE}
                    <Typography component="span" fontWeight={600} mx={1}>
                      {client?.apartaments?.[0]?.title}
                    </Typography>
                  </Typography>
                  <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item md={12} xs={12}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          sx={{ padding: 0 }}
                        >
                          {block?.settings?.titleOfList}
                        </AccordionSummary>
                        <AccordionDetails>
                          {client?.virtualCards?.map((card: IVirtualCard) => (
                            <Stack direction="row" spacing={2} key={card.id}>
                              <Typography>{card?.cardNumber}</Typography>
                              <Typography>{card?.balance} ₪</Typography>
                            </Stack>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Stack>
          </Stack>
          <Divider sx={{ width: '100%' }} />
        </Container>
      ) : (
        <Container maxWidth={isMobile ? 'sm' : 'xl'}>
          <Stack direction="column" alignItems="center">
            {!filteredSupplierHistory.length && (
              <Typography
                sx={{ margin: '0 auto' }}
                textAlign="center"
                variant="h6"
                color="secondaryContrastText"
              >
                אין היסטוריה
              </Typography>
            )}

            {filteredSupplierHistory.map((item) => {
              if ('cardNumber' in item) return <></>;

              const { id, creator, amount, order, transactionType } = item;
              const orderImages = order?.documents?.map((doc: DocumentImage) => doc.url) || [];

              return (
                <Stack direction="row" sx={{ width: '100%', maxWidth: '393px' }} key={id}>
                  <Box mt={1} width="100%">
                    <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between">
                      <Typography>
                        {CLIENT_NAME_TITLE}
                        <Typography component="span" fontWeight={600} mx={1}>
                          {creator?.firstName} {creator?.lastName}
                        </Typography>
                      </Typography>
                      {block?.settings?.variant === 'isExchange' && (
                        <Typography
                          sx={{
                            fontWeight: 1000,
                            color: transactionType === 'DEBIT' ? 'green' : '#00bfff',
                          }}
                        >
                          {transactionType}
                        </Typography>
                      )}
                    </Stack>

                    <Typography>
                      {ADDRESS_TITLE}
                      <Typography component="span" fontWeight={600} mx={1}>
                        {creator?.address}
                      </Typography>
                    </Typography>

                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Stack direction="row" spacing={2}>
                        <Typography>
                          {DATE_TITLE}
                          <Typography sx={{ fontWeight: 600 }} component="span" mx={1}>
                            {order?.createdAt
                              ? format(new Date(order.createdAt), 'dd MMM yyyy')
                              : ''}
                          </Typography>
                        </Typography>
                        <Typography component="span">
                          {PRICE_TITLE}
                          <Typography sx={{ fontWeight: 600 }} component="span" mx={1}>
                            {amount} ₪
                          </Typography>
                        </Typography>
                      </Stack>
                      <Iconify
                        icon="carbon:document-view"
                        width={42}
                        height={39}
                        onClick={() => {
                          if (modalId) {
                            dispatch({
                              type: ActionTypes.ADD_ORDER_IMAGES,
                              payload: orderImages,
                            });
                            setActiveDialog(modalId);
                          }
                        }}
                      />
                    </Stack>

                    {block?.settings?.variant === 'default' && (
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row" spacing={2} py={2}>
                          {actionButtons.map((actionButton: ButtonField, idx: number) => (
                            <Button
                              component={
                                actionButton?.link?.includes('modal') ||
                                actionButton?.link?.includes('#')
                                  ? Button
                                  : NextLink
                              }
                              variant={actionButton?.variant || 'contained'}
                              color={getButtonColor(actionButton?.color, order?.status)}
                              size={actionButton?.size || 'small'}
                              key={`actionButton_${idx}`}
                              sx={{
                                borderRadius: 10,
                                textWrap: 'nowrap',
                              }}
                              href={
                                (!actionButton?.link?.includes('modal') &&
                                  !actionButton.isDialogToggler &&
                                  !actionButton.link?.includes('#') &&
                                  actionButton?.link) ||
                                ''
                              }
                              onClick={() => {
                                if (actionButton.isDialogToggler && actionButton.modalId) {
                                  setActiveDialog(actionButton.modalId);
                                }
                                if (actionButton.link?.includes('#')) {
                                  const newLink = actionButton.link.replace('#', '');
                                  window.location.href = `#${newLink}`;
                                }
                                if (
                                  getButtonColor(actionButton?.color, order?.status) === 'warning'
                                ) {
                                  dispatch({
                                    type: ActionTypes.SET_SELECTED_ORDER,
                                    payload: order,
                                  });
                                }
                                dispatch({ type: ActionTypes.SET_UPDATED_ORDER, payload: order });
                              }}
                            >
                              {getButtonColor(actionButton?.color, order?.status) === 'warning'
                                ? APPROVED_ORDER_BTN_TEXT
                                : actionButton.label || 'No label'}
                            </Button>
                          ))}
                        </Stack>
                      </Stack>
                    )}
                    <Divider sx={{ width: '100%' }} />
                  </Box>
                </Stack>
              );
            })}
          </Stack>
        </Container>
      )}
    </>
  );
};

export default SupplierHistoryCityPeople;
