import React, { ChangeEvent, useMemo, useRef, useState } from 'react';

import { Box, Button, Stack, Typography } from '@mui/material';

// eslint-disable-next-line import/no-cycle
import { uploadFile } from 'src/api/upload-file';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { SUPPLIER_HISTORY_ITEMS } from 'src/_mock/_supplier-history';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import Upload from 'src/components/upload/upload';

import { ButtonField } from 'src/types/generator';
import { ComponentTypeProps } from 'src/types/page-generator';

import { useAuthContext } from 'src/auth/hooks';
import { addToCart } from 'src/api/citypeople';
import { Ib2bProduct } from 'src/types/b2b';
import { IVirtualCard } from 'src/types/club-cards';
import { checkout } from 'src/api/basket';
import { addOrderDocument } from 'src/api/order';
import { fCurrency } from 'src/utils/format-number';
import { getContentValueFromProps } from '../../../utils';

type UploadFile = File & { preview: any };

const getFileWithPreview = (file: File): UploadFile =>
  Object.assign(file, { preview: URL.createObjectURL(file), path: file.name });

export const PaymentCityPeople = ({ block }: ComponentTypeProps) => {
  const getFieldValue = getContentValueFromProps(block);
  const [isLoadingFile, setLoadingFile] = useState(false);
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [fileLinks, setFileLinks] = useState<string[]>([]);
  const { state, dispatch } = useAppContext();
  const { user } = useAuthContext();

  const { image } = SUPPLIER_HISTORY_ITEMS[1];

  const [button] = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [block?.actionButtons]
  );
  const title = useMemo(() => getFieldValue('mainTitle'), [block?.mainTitle]);

  const text = useMemo(() => getFieldValue('text'), [block?.text]);

  const subTitle = useMemo(() => getFieldValue('subTitle'), [block?.subTitle]);

  const isClient = !state?.customer?.merchants?.length;
  const isSupplierMode = useMemo(() => getFieldValue('isSupplierMode'), [block?.isSupplierMode]);
  const isAlternativeDesign = useMemo(
    () => getFieldValue('settings.isAlternativeDesign'),
    [block?.settings.isAlternativeDesign]
  );

  const input = useRef<any>();

  const isDisableClientButton = useMemo(
    () => !!files.length && !fileLinks.length,
    [files, fileLinks]
  );
  const isDisableSupplierButton = useMemo(
    () => isLoadingFile || !fileLinks.length,
    [isLoadingFile, fileLinks]
  );

  const handleSelectByButton = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const fileList: FileList = event.target.files;
      const filesWithPreview = Array.from(fileList).map((file) => getFileWithPreview(file));

      setFiles(filesWithPreview);
    }
  };

  const handleSelectByUpload = (selectedFiles: File[]) => {
    if (selectedFiles.length) {
      const filesWithPreview = selectedFiles.map((file) => getFileWithPreview(file));
      setFiles(filesWithPreview);
    }
  };

  const handleRemoveFile = (inputFile: File | string) => {
    const filesFiltered = files.filter((fileFiltered) => fileFiltered !== inputFile);
    setFiles(filesFiltered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
    setFileLinks([]);
  };

  const handleLoadFiles = async () => {
    if (files.length) {
      setLoadingFile(true);

      try {
        const readyLinks = await Promise.all(files.map((file) => uploadFile(file)));

        setFileLinks(readyLinks);
      } catch (error) {
        console.error('Error uploading files:', error);
      } finally {
        setLoadingFile(false);
      }
    }
  };

  const handleLoadBySupplier = async (supplFiles: File[]) => {
    if (supplFiles.length) {
      setLoadingFile(true);

      try {
        const filesWithPreview = supplFiles.map((f) => getFileWithPreview(f));
        const filesLinks = await Promise.all(
          filesWithPreview.map((modFile) => uploadFile(modFile))
        );

        setFiles(filesWithPreview);
        setFileLinks(filesLinks);
      } catch (error) {
        console.error('Error uploading files:', error);
      } finally {
        setLoadingFile(false);
      }
    }
  };

  const handleUpdateOrderDocuments = async () => {
    if (state.updatedOrder && fileLinks.length) {
      addOrderDocument(fileLinks[0], state.updatedOrder.id, dispatch)
        .then((data) => {
          dispatch({ type: ActionTypes.SET_NEW_ORDER, payload: data });
        })
        .catch((e) => {
          console.log(`Error during addOrderDocument: ${e}`);
        })
        .finally(() => {
          dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
        });
    }
  };

  const handleSendData = async () => {
    if (!state.products?.length) throw new Error('No available products!');
    if (!state.selectedMerchant?.id) throw new Error('No selected merchant!');
    if (!state.selectedVirtualCard?.id) throw new Error('No selected virtualCard!');

    const [relatedProduct] = state.products.filter((product: Ib2bProduct) =>
      product.virtualCards?.some((card: IVirtualCard) => card.id === state.selectedVirtualCard?.id)
    );

    if (user) {
      try {
        const success = await addToCart(dispatch, {
          productId: relatedProduct?.id || '',
          smbAccountId: state.selectedMerchant?.smbAccount?.id || '',
          priceAtPurchase: Number(state.orderCost || 0),
          activityId: state.smbActivityId || '',
          amount: 1,
        });

        if (success) {
          await checkout(
            state.selectedVirtualCard?.id || '',
            state.selectedMerchant?.smbAccount?.id || '',
            dispatch,
            fileLinks,
            'PENDING',
            state.selectedApartment?.customerUserSsoId || '',
            state.smbActivityId || ''
          );

          dispatch({ type: ActionTypes.GET_ORDERS_HISTORY, payload: [] });
        } else {
          console.log(`Adding to cart was unsuccessful!`);
        }
      } catch (e) {
        console.log(`Error during add to cart: ${e}`);
      }
    }

    if (button.isDialogToggler && button.modalId) {
      dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: button.modalId || null });
    }
  };

  const handleClick = () => {
    if (files.length && fileLinks.length) {
      if (isClient) {
        handleSendData();
      } else {
        handleUpdateOrderDocuments();
      }
    } else {
      input.current?.click();
    }
  };

  const messageBoxStyle = {
    width: '100%',
    p: 1,
    mt: 1,
    flexDirection: 'column',
    borderRadius: 2.5,
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    backgroundImage:
      'linear-gradient(62deg, rgba(255, 86, 48, 0.12) 0%, rgba(0, 184, 217, 0.12) 100%)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  };

  if (isSupplierMode) {
    return (
      <Box>
        <Stack p={3} gap={2} alignItems="center" sx={{ m: '0 auto' }} maxWidth="460px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ ...messageBoxStyle, px: 5 }}
          >
            <Typography variant="h6" color="primary.main" textAlign="center">
              {text}
            </Typography>
          </Box>
          <Stack spacing={1} alignItems="center" width="100%" mt={2}>
            <Upload
              multiple={isClient}
              mobileMode
              file={isClient ? null : files[0]}
              files={files}
              thumbnail
              onDrop={handleLoadBySupplier}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => console.info('ON UPLOAD')}
            />
          </Stack>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={isLoadingFile ? <Iconify icon="svg-spinners:8-dots-rotate" /> : <></>}
            fullWidth
            disabled={isDisableSupplierButton}
            sx={{ borderRadius: 10, mt: 3, color: '#fff' }}
            onClick={handleClick}
          >
            לשליחת הזמנה
          </Button>
        </Stack>
      </Box>
    );
  }

  return (
    <Box>
      <Stack
        p={3}
        gap={2}
        alignItems="center"
        sx={{
          m: '0 auto',
          ...(isAlternativeDesign && {
            border: (theme) => `solid 1px ${theme.palette.primary.main}`,
            borderRadius: 3,
            my: 2,
          }),
        }}
        maxWidth="460px"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={isAlternativeDesign ? { flexDirection: 'column' } : messageBoxStyle}
        >
          {isAlternativeDesign ? (
            <>
              {block?.settings?.enableTop && isClient && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography fontSize={22} fontWeight={600} color="primary.main">
                    {title}
                  </Typography>
                  <Typography fontSize={22} fontWeight={600} color="primary.light">
                    {fCurrency(state.orderCost)}
                  </Typography>
                </Stack>
              )}
              <Typography fontSize={22} fontWeight={600} color="primary.main">
                {state.selectedMerchant?.title || ''}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h6" color="primary.main">
                הינכם מעבירים לספק {fCurrency(state.orderCost)}
              </Typography>
              <Typography variant="h6" color="primary.main">
                {state.selectedMerchant?.title || ''}
              </Typography>
            </>
          )}
        </Box>
        <input
          ref={input}
          type="file"
          multiple={isClient}
          onChange={handleSelectByButton}
          style={{ position: 'absolute', zIndex: -10 }}
        />
        {isAlternativeDesign ? (
          <>
            <Typography
              textAlign="center"
              fontWeight={100}
              mt={3}
              sx={{ width: '90%' }}
              color="primary.main"
            >
              {text}
            </Typography>
            <Stack spacing={1} alignItems="center" width="100%" mt={2}>
              {files.length ? (
                <Upload
                  multiple={isClient}
                  mobileMode
                  thumbnail
                  file={isClient ? null : files[0]}
                  files={files}
                  onDrop={handleSelectByUpload}
                  onRemove={handleRemoveFile}
                  onRemoveAll={handleRemoveAllFiles}
                  onUpload={() => console.info('ON UPLOAD')}
                />
              ) : (
                <Box
                  sx={{
                    border: (theme) => `solid 1px ${theme.palette.primary.main}`,
                    borderRadius: 1,
                    backgroundColor: 'primary.lighter',
                    width: '55vw',
                    maxWidth: '250px',
                    height: '40vw',
                    maxHeight: '180px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={handleClick}
                >
                  <Image
                    src="/assets/images/city-people/img-with-asterisk.png"
                    sx={{ width: '25%' }}
                  />
                  <Typography color="secondary.lighter" fontWeight={100} fontSize={12}>
                    העלאת תמונה
                  </Typography>
                </Box>
              )}
              <Box>
                <Button
                  startIcon={<Iconify icon="bi:trash3" color="secondary.lighter" width={18} />}
                  onClick={handleRemoveAllFiles}
                >
                  <Typography fontWeight={100} color="primary.main">
                    הסר
                  </Typography>
                </Button>
                {isLoadingFile ? (
                  <Iconify icon="svg-spinners:8-dots-rotate" />
                ) : (
                  <Button
                    startIcon={
                      <Iconify
                        icon="material-symbols-light:upload"
                        color="secondary.lighter"
                        width={28}
                      />
                    }
                    onClick={handleLoadFiles}
                    disabled={Boolean(fileLinks.length)}
                  >
                    <Typography fontWeight={100} color="primary.main">
                      העלאת תמונה
                    </Typography>
                  </Button>
                )}
              </Box>
            </Stack>
          </>
        ) : (
          <>
            {files.length ? (
              <Stack spacing={1} alignItems="center" width="100%" mt={2}>
                <Upload
                  multiple={isClient}
                  file={isClient ? null : files[0]}
                  files={files}
                  mobileMode
                  thumbnail
                  onDrop={handleSelectByUpload}
                  onRemove={handleRemoveFile}
                  onRemoveAll={handleRemoveAllFiles}
                  onUpload={() => console.info('ON UPLOAD')}
                />
                <Box>
                  <Button
                    startIcon={<Iconify icon="tabler:trash" color="red" />}
                    onClick={handleRemoveAllFiles}
                  >
                    <Typography color="red">הסרה</Typography>
                  </Button>
                  {isLoadingFile ? (
                    <Iconify icon="svg-spinners:8-dots-rotate" />
                  ) : (
                    <Button
                      startIcon={<Iconify icon="tabler:cloud-up" />}
                      onClick={handleLoadFiles}
                      disabled={Boolean(fileLinks.length)}
                    >
                      העלה
                    </Button>
                  )}
                </Box>
              </Stack>
            ) : (
              <Typography textAlign="center" mt={3} sx={{ width: '90%' }} color="primary.main">
                על מנת סיום את העסקה - נא צלמו הזמנת רכש חתומה
              </Typography>
            )}
          </>
        )}

        <Button
          variant="contained"
          size="large"
          startIcon={
            files.length || isAlternativeDesign ? <></> : <Iconify icon="ph:camera-light" />
          }
          fullWidth
          disabled={isDisableClientButton}
          sx={{
            borderRadius: 2,
            mt: 3,
            backgroundColor: 'primary.main',
            ...(isAlternativeDesign && {
              backgroundColor: 'primary.light',
              fontSize: 16,
              fontWeight: 100,
              borderRadius: 3,
              width: 'fit-content',
              minWidth: '150px',
              padding: '0px 40px',
              margin: '16px auto 2px',
            }),
          }}
          onClick={handleClick}
        >
          {files.length ? 'לשליחת הזמנה' : 'צלם'}
        </Button>
      </Stack>
    </Box>
  );
};
