import dayjs from 'dayjs';
import React, { FC, useMemo, useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Stack, Container, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import Iconify from 'src/components/iconify';
import { ComponentTypeProps } from 'src/types/page-generator';
import { IProjectCityPeople } from 'src/types/project-citypeople';
import {
  CustomerHistoryTransaction,
  SupplierHistoryTransaction,
} from 'src/types/history-city-people';
import { getProjects } from 'src/api/citypeople';
import { useResponsive } from 'src/hooks/use-responsive';
import { getContentValueFromProps } from '../../utils';

const HistorySearchCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const isMobile = useResponsive('down', 'sm');
  const { state, dispatch } = useAppContext();

  const [dateFrom, setDateFrom] = useState<dayjs.Dayjs | null>(null);
  const [dateTo, setDateTo] = useState<dayjs.Dayjs | null>(null);
  const [search, setSearch] = useState('');
  const [selectedProject, setSelectedProject] = useState('');

  const isClient = !state?.customer?.merchants?.length;
  const isAlternativeDesign = useMemo(
    () => getFieldValue('settings.isAlternativeDesign') ?? false,
    [block?.settings.isAlternativeDesign]
  );

  useEffect(() => {
    getProjects(dispatch);
  }, []);

  useEffect(() => {
    const filteredHistory: Record<
      string,
      Array<CustomerHistoryTransaction | SupplierHistoryTransaction>
    > = {};

    Object.entries(state.orderHistory).forEach(([apartmentTitle, transactions]) => {
      const filteredTransactions = transactions.filter((transaction) => {
        if (dateFrom || dateTo) {
          if (!transaction.order?.createdAt) return false;

          const transactionDate = dayjs(transaction.order.createdAt);

          const isAfterFrom = dateFrom ? transactionDate.isAfter(dateFrom) : true;
          const isBeforeTo = dateTo ? transactionDate.isBefore(dateTo) : true;

          if (!isAfterFrom || !isBeforeTo) return false;
        }

        // if (selectedProject && transaction.order?.projectId !== selectedProject) {
        //   return false;
        // }

        if (search) {
          const searchLower = search.toLowerCase();

          if (isClient) {
            const customerTransaction = transaction as CustomerHistoryTransaction;

            return (
              customerTransaction.merchantTitle?.toLowerCase().includes(searchLower) ||
              customerTransaction.orderNumber?.toLowerCase().includes(searchLower) ||
              customerTransaction.cardTypeName?.toLowerCase().includes(searchLower)
            );
          }

          const supplierTransaction = transaction as SupplierHistoryTransaction;

          return (
            supplierTransaction.creator?.address?.toLowerCase().includes(searchLower) ||
            supplierTransaction.creator?.email?.toLowerCase().includes(searchLower) ||
            supplierTransaction.creator?.firstName?.toLowerCase().includes(searchLower) ||
            supplierTransaction.creator?.lastName?.toLowerCase().includes(searchLower)
          );
        }

        return true;
      })

      if (filteredTransactions.length > 0) {
        filteredHistory[apartmentTitle] = filteredTransactions;
      }
    });

    dispatch({
      type: ActionTypes.SET_FILTERED_ORDERS_HISTORY,
      payload: filteredHistory,
    });

    dispatch({
      type: ActionTypes.SET_FILTER_QUERY,
      payload: {
        searchQuery: search || dateFrom || dateTo ? 'active' : '',
        type: '',
      },
    });
  }, [dateFrom, dateTo, search, selectedProject, state.orderHistory]);

  function CustomOpenPickerIcon() {
    return <Iconify color="primary.light" icon="mage:calendar-2" width={20} />;
  }

  const handleProjectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedProject(event.target.value as string);
  };

  return (
    <Container maxWidth={isMobile ? 'sm' : 'lg'}>
      {isAlternativeDesign ? (
        <Stack
          direction="column"
          alignItems="center"
          sx={{
            margin: '0 auto 30px',
            width: { xs: '100%' },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
              sx={{ width: '100%', mb: '5px' }}
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
              gap={2.5}
            >
              <DatePicker
                label="מתאריך"
                value={dateFrom}
                onChange={(newValue) => setDateFrom(newValue)}
                slots={{ openPickerIcon: CustomOpenPickerIcon }}
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    flexDirection: 'row-reverse',
                  },
                  '& input': {
                    textAlign: 'end',
                  },
                }}
              />
              <DatePicker
                label="עד תאריך"
                value={dateTo}
                onChange={(newValue) => setDateTo(newValue)}
                slots={{ openPickerIcon: CustomOpenPickerIcon }}
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    flexDirection: 'row-reverse',
                  },
                  '& input': {
                    textAlign: 'end',
                  },
                }}
              />
            </Stack>
          </LocalizationProvider>

          <Stack
            width={1}
            direction={block?.settings?.variant === 'default' ? 'row' : 'column'}
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <TextField
              fullWidth
              label="חיפוש"
              value={search}
              sx={{
                mt: 1,
              }}
              InputProps={{
                startAdornment: (
                  <Iconify color="primary.light" icon="mingcute:search-line" width={20} />
                ),
              }}
              onChange={(e) => setSearch(e.target.value)}
            />

            {block?.settings?.enableList && (
              <TextField
                label="בחר פרויקט"
                select
                fullWidth
                sx={{ mt: 1 }}
                value={selectedProject}
                onChange={handleProjectChange}
              >
                <MenuItem value="">
                  <em>כל הפרויקטים</em>
                </MenuItem>
                {state?.projects?.map((option: IProjectCityPeople) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    sx={{
                      mx: 1,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction="column"
          alignItems="center"
          sx={{
            margin: '0 auto',
            width: { xs: '100%', sm: '340px' },
          }}
        >
          <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between" gap={2}>
            <TextField
              sx={{ width: '100%' }}
              type="date"
              label="מתאריך"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setDateFrom(dayjs(e.target.value))}
            />
            <TextField
              sx={{ width: '100%' }}
              type="date"
              label="עד תאריך"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setDateTo(dayjs(e.target.value))}
            />
          </Stack>

          <Stack width={1} direction="column" gap={2} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="חיפוש"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            {block?.settings?.enableList && (
              <TextField
                label="בחר פרויקט"
                select
                fullWidth
                value={selectedProject}
                onChange={handleProjectChange}
              >
                <MenuItem value="">
                  <em>כל הפרויקטים</em>
                </MenuItem>
                {state?.projects?.map((option: IProjectCityPeople) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    sx={{
                      mx: 1,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
        </Stack>
      )}
    </Container>
  );
};

export default HistorySearchCityPeople;
