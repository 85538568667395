import React, { useState } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { cancelOrder, changeOrderStatus } from 'src/api/basket';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import Iconify from 'src/components/iconify';

import { ComponentTypeProps } from 'src/types/page-generator';

import { useTranslate } from '../../../../../locales';

export const ApproveOrderCityPeople = ({ block }: ComponentTypeProps) => {
  const { state, dispatch } = useAppContext();
  const { t } = useTranslate();
  const [isLoading, setLoadingStatus] = useState(false);

  const messageBoxStyle = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    my: 1.5,
    p: 3,
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 2.5,
    backdropFilter: 'blur(20px)',
    backgroundColor: block?.settings?.hasBackground ? 'white' : block?.settings?.bgColor,
    backgroundImage: block?.settings?.hasBackground
      ? 'linear-gradient(62deg, rgba(255, 86, 48, 0.12) 0%, rgba(0, 184, 217, 0.12) 100%)'
      : '',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  };

  const handleApprove = () => {
    if (state.selectedOrder && typeof state.selectedOrder?.id === 'string') {
      setLoadingStatus(true);

      changeOrderStatus(dispatch, state.selectedOrder.id, 'PAID')
        .catch((e) => {
          console.log(`Error during changeOrderStatus:`, e);
        })
        .finally(() => {
          setLoadingStatus(false);
          dispatch({ type: ActionTypes.SET_SELECTED_ORDER, payload: null });
        });
    }
  };

  const handleCancel = () => {
    if (state.selectedOrder && typeof state.selectedOrder?.id === 'string') {
      setLoadingStatus(true);

      cancelOrder(dispatch, state.selectedOrder.id)
        .catch((e) => {
          console.log(`Error during canceling the order:`, e);
        })
        .finally(() => {
          setLoadingStatus(false);
          dispatch({ type: ActionTypes.SET_SELECTED_ORDER, payload: null });
        });
    }
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" sx={messageBoxStyle}>
      {state.updatedOrder && state.updatedOrder.status === 'PENDING' ? (
        <Typography sx={{ color: block?.settings?.textColor }}>
          אתם לא יכולים יותר לבצע פעולות עם ההזמנה הזו.
        </Typography>
      ) : (
        <>
          <Typography sx={{ color: block?.settings?.textColor }}>הסדר ממתין לפעולה</Typography>
          <Typography sx={{ color: block?.settings?.textColor }}>
            אתה יכול לאשר או לבטל אותו
          </Typography>
        </>
      )}
      <Box
        sx={{
          width: '100%',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading && <Iconify icon="svg-spinners:8-dots-rotate" />}
      </Box>
      {state.updatedOrder && state.updatedOrder.status === 'PENDING' ? (
        <Box
          sx={{
            width: '80%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'no-wrap',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <Button
            variant="outlined"
            color="success"
            key="approve-order-confirm"
            sx={{ width: '40%', borderRadius: 10 }}
            onClick={handleApprove}
            disabled={!state.selectedOrder || isLoading}
          >
            אשר
          </Button>

          <Button
            variant="outlined"
            color="error"
            key="approve-order-decline"
            sx={{ width: '40%', borderRadius: 10 }}
            onClick={handleCancel}
            disabled={!state.selectedOrder || isLoading}
          >
            בטל
          </Button>
        </Box>
      ) : (
        <Typography sx={{ color: block?.settings?.textColor }}>
          סטטוס ההזמנה: {state.updatedOrder && `${t(state.updatedOrder.status)}`}
        </Typography>
      )}
    </Box>
  );
};
